import React from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight2 } from "react-icons/im";

function AboutCard() {
  return (
<Card className="quote-card-view">
  <Card.Body>
    <blockquote className="blockquote mb-0">
      <p style={{ textAlign: "justify" }}>
        Greetings to all, I am from <span className="purple">Málaga, Spain </span>!
        <br />
        My academic background includes a studies in Web Development and a Master's in Fine Arts,
        which have provided me with a diverse set of skills.
        <br />
        Professionally, I've amassed experience in Web Development for a Casino Slot multinational, React forms, and I've also worked on 3D workflow in Game Development for Blockchain projects.
        <br />
        <br />
        Outside of my professional life, here are a few of my favorite pastimes:
      </p>
      <ul>
        <li className="about-activity">
          <ImArrowRight2 /> Gaming with my partner
        </li>
        <li className="about-activity">
          <ImArrowRight2 /> Reading hard Sci-fi
        </li>
        <li className="about-activity">
          <ImArrowRight2 /> Making music
        </li>
      </ul>
    </blockquote>
  </Card.Body>
</Card>

  );
}

export default AboutCard;
